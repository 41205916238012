import { isBurns } from '../helpers/utils.helper';

export const MIN_MANAGER_PWD_CHAR_REQUIRED = 14;

export const LOGO = isBurns
  ? { src: '/assets/tbw-logo.svg', alt: 'the Burns Way logo' }
  : { src: '/assets/talkingstick_logo_full_1200.png', alt: 'Talking Stick logo' };

export const SMALL_LOGO = isBurns
  ? { src: '/assets/tbw-logo-sm.svg', alt: 'the Burns Way logo' }
  : { src: '/assets/ts-logo-sm.png', alt: 'Talking Stick logo' };
